import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";

import "./styles/_variables.css";
import "./styles/_sanitize.css";
import "./styles/base.css";
import "./styles/section.css";
import "./styles/container.css";
import "./styles/text-container.css";
import "./styles/ticker.css";
import "./styles/list.css";
import "./styles/space.css";
import "./styles/details.css";

new Vue({
  router,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");

<style>
.accordion__details {
  display: flex;
  flex-wrap: wrap;
  animation: fade-in 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.accordion__left {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
}

.accordion__right {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
}

.accordion__right img {
  border: 4px solid var(--color-primary);
  box-shadow: 15px 15px 0px 0px var(--color-primary);
}

@media (min-width: 800px) {
  .accordion__left {
    width: 70%;
    padding-left: 10px;
    padding-right: 10%;
  }

  .accordion__right {
    width: 50%;
    padding-left: 5%;
    padding-right: 10%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>


<template>
  <main>
    <section id="work" class="section section--inverted">
      <h4>
        <router-link to="/"> &#8592; &#8592; &#8592; &#8592; &#8592;</router-link>
      </h4>
      <ul class="list">

        <li :id="i" :key="i" v-for="(project, i) in projects">
          <div class="accordion">
            <a class="accordion__summary" :href="`#${i}`" @click="toggle(project, i)">
              <h3>{{project.title}}</h3>
            </a>
            <div class="accordion__details" v-show="active === project.path">
              <div class="accordion__left markdown" v-html="marked(project.content)"></div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
import { projects } from "../db.json";
import marked from "marked";

export default {
  data() {
    return {
      active: false,
      projects: projects,
      marked
    };
  },
  methods: {
    toggle(project, id) {
      if (this.active === project.path) {
        this.active = "";
      } else {
        this.active = project.path;
      }
    }
  }
};
</script>

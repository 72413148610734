import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Work from "./pages/Work";
import About from "./pages/About";
import Home from "./pages/Home";

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/work",
      name: "work",
      component: Work
    },
    {
      path: "/about",
      name: "about",
      component: About
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
